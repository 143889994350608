import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

type Props = {
  label: string,
  fieldType: string,
  minValue: string|null,
  maxValue: string|null,
  setMinValue: (value:string|null) => void,
  setMaxValue: (value:string|null) => void,
  hasMinValueError: boolean,
  hasMaxValueError: boolean,
  isFieldSetOpen: boolean,
  setIsFieldSetOpen: (value:boolean) => void,
  hasInvalidValues: boolean,
};

const RangeFieldSet: React.FC<Props> = ({
  label,
  fieldType,
  minValue,
  maxValue,
  setMinValue,
  setMaxValue,
  hasMinValueError,
  hasMaxValueError,
  isFieldSetOpen,
  setIsFieldSetOpen,
  hasInvalidValues,
}) => {

  const closeFieldSet = (e) => {
    e.preventDefault();
    if (hasInvalidValues) { return; }
    setIsFieldSetOpen(false);
  }

  const resetEntryRange = (e) => {
    e.preventDefault();
    setMinValue(null);
    setMaxValue(null);
    setIsFieldSetOpen(false);
  }

  return(
    <>
      <a onClick={() => setIsFieldSetOpen(true)}>
        <div className="d-flex justify-content-between align-items-center">
          <label className="trade-offcanvas__label">
            <i className={
              classnames("las", isFieldSetOpen ? "la-angle-down" : "la-angle-right")
            }></i>
            {" "}
            {label}
          </label>
          {!isFieldSetOpen && <div className="text-end">
            {(!!minValue || !!maxValue) ? (
              `${minValue ?? ''}〜${maxValue ?? ''}`
            ) : (
              "指定しない"
            )}
          </div>}
        </div>
      </a>

      {isFieldSetOpen && <div className="d-flex">
        <div className="input-group trade-offcanvas__input-group">
          <input
            type={fieldType}
            value={minValue ?? ""}
            onChange={(e) => setMinValue(e.target.value)}
            className={classnames(
              "form-control trade-offcanvas__form-control",
              hasMinValueError && "is-invalid",
            )}
          />
          <span className="input-group-text trade-offcanvas__input-group-text">〜</span>
          <input
            type={fieldType}
            value={maxValue ?? ""}
            onChange={(e) => setMaxValue(e.target.value)}
            className={classnames(
              "form-control trade-offcanvas__form-control",
              hasMaxValueError && "is-invalid",
            )}
          />
        </div>
        <div className="mx-2">
          <button
            type="button"
            className="btn btn-outline-primary trade-offcanvas__square-button"
            onClick={closeFieldSet}
          >
            <i className="las la-check"></i>
          </button>
        </div>
        <div className="text-danger d-flex align-items-center">
          <a onClick={resetEntryRange}>
            <i className="las la-times"></i>
          </a>
        </div>
      </div>}
    </>
  );

};

export default RangeFieldSet;