import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Rails from 'rails-ujs';
import classnames from 'classnames';
import RangeFieldSet from './RangeFieldSet';
import RequiredNumberField from './RequiredNumberField';

enum InputAttributeName {
  EntryTimeNotAfter,
  EntryTimeNotBefore,
  MaxEntryPrice,
  MinEntryPrice,
  MaxHoldingBusinessDays,
  TrailingStopTriggerPct,
  TrailingStopProfitPct,
  TakeProfitPct,
  StopLossPct,
  Quantity,
};

type Props = {
  defaultEntryTimeNotBefore: string|null,
  defaultEntryTimeNotAfter: string|null,
  defaultMinEntryPrice: string|null,
  defaultMaxEntryPrice: string|null,
  defaultMaxHoldingBusinessDays: string|null,
  defaultTrailingStopTriggerPct: string|null, //AiTradeTrailingStopStep
  defaultTrailingStopProfitPct: string|null, //AiTradeTrailingStopStep
  defaultTakeProfitPct: string|null,
  defaultStopLossPct: string|null,
  defaultQuantity: string|null,
  instrument: {
    title: string,
    ticker: string,
    tradingLot: number,
  },
  isPersisted: boolean,
  formUrl: string,
  formMethod: string,
  deleteFormUrl: string,
  tradingMode: string,
  tradingModeText: string,
  isEntryCandidate: boolean,
  isExitCandidate: boolean,
  canEditTrailingStop: boolean,
};

type Quote = {
  currentPrice: number|null,
  currentPriceAt: Date|null,
  dailyChange: number|null,
  dailyChangePct: number|null,
};

// https://chatgpt.com/share/675d45d4-9b34-8000-b690-1ae5d3edc62f
const useVisibility = (ref, options) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isVisible;
}

const getTimeInJST = (date: Date): string => {
  if (!date) return "";
  return date.toLocaleTimeString('en-US', {
    timeZone: 'Asia/Tokyo',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false  // Use 24-hour format
  }).replace(/,/g, '');
}

const StrategyForm: React.FC<Props> = ({
  defaultEntryTimeNotBefore,
  defaultEntryTimeNotAfter,
  defaultMinEntryPrice,
  defaultMaxEntryPrice,
  defaultMaxHoldingBusinessDays,
  defaultTrailingStopTriggerPct,
  defaultTrailingStopProfitPct,
  defaultTakeProfitPct,
  defaultStopLossPct,
  defaultQuantity,
  instrument,
  isPersisted,
  formUrl,
  formMethod,
  deleteFormUrl,
  tradingMode,
  tradingModeText,
  isEntryCandidate,
  isExitCandidate,
  canEditTrailingStop,
}) => {
  const [entryTimeNotBefore, setEntryTimeNotBefore] = useState<string|null>(defaultEntryTimeNotBefore);
  const [entryTimeNotAfter, setEntryTimeNotAfter] = useState<string|null>(defaultEntryTimeNotAfter);
  const [isEntryTimeFieldSetOpen, setIsEntryTimeFieldSetOpen] = useState<boolean>(false);
  const [minEntryPrice, setMinEntryPrice] = useState<string|null>(defaultMinEntryPrice);
  const [maxEntryPrice, setMaxEntryPrice] = useState<string|null>(defaultMaxEntryPrice);
  const [isEntryPriceFieldSetOpen, setIsEntryPriceFieldSetOpen] = useState<boolean>(false);
  const [maxHoldingBusinessDays, setMaxHoldingBusinessDays] = useState<string|null>(defaultMaxHoldingBusinessDays);
  const [isMaxHoldingBusinessDaysFieldOpen, setIsMaxHoldingBusinessDaysFieldOpen] = useState<boolean>(false);
  const [trailingStopTriggerPct, setTrailingStopTriggerPct] = useState<string|null>(defaultTrailingStopTriggerPct);
  const [trailingStopProfitPct, setTrailingStopProfitPct] = useState<string|null>(defaultTrailingStopProfitPct);
  const [isTrailingStopFieldSetOpen, setIsTrailingStopFieldSetOpen] = useState<boolean>(false);
  const [takeProfitPct, setTakeProfitPct] = useState<string|null>(defaultTakeProfitPct);
  const [stopLossPct, setStopLossPct] = useState<string|null>(defaultStopLossPct);
  const [quantity, setQuantity] = useState<string|null>(defaultQuantity);
  const [quote, setQuote] = useState<Quote|null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [didTrySubmit, setDidTrySubmit] = useState<boolean>(false);
  const wrapperRef = useRef(null);
  const formRef = useRef(null);
  const deleteFormRef = useRef(null);
  const isVisible = useVisibility(wrapperRef, { threshold: 0.0 });

  const updateQuote = async () => {
    try {
      const response = await axios.request({
        url: "/ai/trade_strategies/quote",
        method: 'get',
        params: {
          instrument_ticker: instrument?.ticker,
        }
      });
      const quote: Quote = {
        currentPrice: response.data.current_price,
        currentPriceAt: (
          response.data.current_price_at ? new Date(response.data.current_price_at*1000) : null
        ),
        dailyChange: response.data.daily_change,
        dailyChangePct: response.data.daily_change_pct,
      };
      setQuote(quote);
    } catch (error) {
      setQuote(null);
    }
  };

  useEffect(() => {
    if (!isVisible) return;
    updateQuote();
  }, [isVisible]);

  const selectInvalidInputAttributes = () : InputAttributeName[] => {
    let invalidAttributes: InputAttributeName[] = [];
    if (entryTimeNotAfter && entryTimeNotBefore && entryTimeNotBefore >= entryTimeNotAfter) {
      invalidAttributes.push(InputAttributeName.EntryTimeNotBefore);
      invalidAttributes.push(InputAttributeName.EntryTimeNotAfter);
    }
    if (minEntryPrice && maxEntryPrice && Number(minEntryPrice) >= Number(maxEntryPrice)) {
      invalidAttributes.push(InputAttributeName.MinEntryPrice);
      invalidAttributes.push(InputAttributeName.MaxEntryPrice);
    }
    if (maxHoldingBusinessDays && Number(maxHoldingBusinessDays) <= 0) {
      invalidAttributes.push(InputAttributeName.MaxHoldingBusinessDays);
    }
    if (trailingStopTriggerPct || trailingStopProfitPct) {
      if (!trailingStopTriggerPct || Number(trailingStopTriggerPct) <= 0) {
        invalidAttributes.push(InputAttributeName.TrailingStopTriggerPct);
      }
      if (!trailingStopProfitPct || Number(trailingStopProfitPct) <= 0) {
        invalidAttributes.push(InputAttributeName.TrailingStopProfitPct);
      }
      if (Number(trailingStopTriggerPct) <= Number(trailingStopProfitPct)) {
        invalidAttributes.push(InputAttributeName.TrailingStopTriggerPct);
        invalidAttributes.push(InputAttributeName.TrailingStopProfitPct);
      }
    }
    if (!takeProfitPct){
      invalidAttributes.push(InputAttributeName.TakeProfitPct);
    } else if (Number(takeProfitPct) <= 0) {
      invalidAttributes.push(InputAttributeName.TakeProfitPct);
    } else if (Number(takeProfitPct) >= 10000) {
      invalidAttributes.push(InputAttributeName.TakeProfitPct);
    }
    if (!stopLossPct) {
      invalidAttributes.push(InputAttributeName.StopLossPct);
    } else if (Number(stopLossPct) <= 0) {
      invalidAttributes.push(InputAttributeName.StopLossPct);
    } else if (Number(stopLossPct) >= 10000) {
      invalidAttributes.push(InputAttributeName.StopLossPct);
    }
    if (!quantity) {
      invalidAttributes.push(InputAttributeName.Quantity);
    } else if (Number(quantity) <= 0) {
      invalidAttributes.push(InputAttributeName.Quantity);
    } else if (Number(quantity) % instrument.tradingLot !== 0) {
      invalidAttributes.push(InputAttributeName.Quantity);
    }
    return invalidAttributes;
  }
  const invalidInputAttributes = selectInvalidInputAttributes();
  const includeInvalidAttributes = (attributes: InputAttributeName[]) => {
    return invalidInputAttributes.some((attribute) => attributes.includes(attribute));
  }

  const canSubmit = (
    invalidInputAttributes.length === 0 &&
    !!instrument && !!quote?.currentPrice && !isSubmitting
  );

  const closeMaxHoldingBusinessDaysFieldSet = (e) => {
    e.preventDefault();
    if (!maxHoldingBusinessDays || Number(maxHoldingBusinessDays) > 0) {
      setIsMaxHoldingBusinessDaysFieldOpen(false);
    }
  }
  const resetMaxHoldingBusinessDaysFieldSet = (e) => {
    e.preventDefault();
    setMaxHoldingBusinessDays(null);
    setIsMaxHoldingBusinessDaysFieldOpen(false);
  }

  const closeTrailingStopFieldSet = (e) => {
    e.preventDefault();
    const hasInvalidValues = includeInvalidAttributes([
      InputAttributeName.TrailingStopTriggerPct,
      InputAttributeName.TrailingStopProfitPct,
    ]);
    if (!hasInvalidValues) {
      setIsTrailingStopFieldSetOpen(false);
    }
  };
  const resetTrailingStopFieldSet = (e) => {
    e.preventDefault();
    setTrailingStopTriggerPct(null);
    setTrailingStopProfitPct(null);
    setIsTrailingStopFieldSetOpen(false);
  }

  const dailyChangeCssClasses = () => {
    const commonClasses = [
      "mt-1",
      "trade-offcanvas__daily-change-text",
    ];
    if (quote.dailyChange > 0) {
      return classnames(...commonClasses, "trade-offcanvas__daily-change-text_positive");
    } else if (quote.dailyChange < 0) {
      return classnames(...commonClasses, "trade-offcanvas__daily-change-text_negative");
    } else {
      return classnames(commonClasses);
    }
  };

  return(
    <div ref={wrapperRef}>
      <div className="d-flex">
        <div>
          <div className="trade-offcanvas__last-price-label">現在値</div>
          <div className="trade-offcanvas__last-price-text">
            {!!quote?.currentPrice ? (
              quote.currentPrice.toLocaleString()
            ) : (
              "取得中..."
            )}
          </div>
          {!!quote?.currentPrice && <div className="trade-offcanvas__last-price-unit-text">
            円
          </div>}
        </div>
        <div className="ms-3">
          {quote?.currentPriceAt && <div className="trade-offcanvas__last-price-label">
            {getTimeInJST(quote?.currentPriceAt)}更新
          </div>}
          {quote?.dailyChange && quote?.dailyChangePct && <div className={dailyChangeCssClasses()}>
            {quote.dailyChange > 0 ? `+${quote.dailyChange.toString()}` : quote.dailyChange}
            <div className="trade-offcanvas__last-price-unit-text">円</div>
            /
            {quote.dailyChangePct > 0 ? `+${quote.dailyChangePct}` : quote.dailyChangePct}
            <div className="trade-offcanvas__last-price-unit-text">%</div>
          </div>}
        </div>
        <div className="ms-3">
          <div className="trade-offcanvas__last-price-label">取引モード</div>
          <div className="mt-1">
            {tradingModeText}
            <a href="/ai/account">
              <i className="las la-cog"></i>
            </a>
          </div>
        </div>
      </div>
      <hr className="my-2" />

      {(isEntryCandidate || !isExitCandidate) && <>
        <RangeFieldSet
          label="エントリー時間"
          fieldType="time"
          minValue={entryTimeNotBefore}
          maxValue={entryTimeNotAfter}
          setMinValue={setEntryTimeNotBefore}
          setMaxValue={setEntryTimeNotAfter}
          hasMinValueError={invalidInputAttributes.includes(InputAttributeName.EntryTimeNotBefore)}
          hasMaxValueError={invalidInputAttributes.includes(InputAttributeName.EntryTimeNotAfter)}
          isFieldSetOpen={isEntryTimeFieldSetOpen}
          setIsFieldSetOpen={setIsEntryTimeFieldSetOpen}
          hasInvalidValues={includeInvalidAttributes([
            InputAttributeName.EntryTimeNotBefore,
            InputAttributeName.EntryTimeNotAfter,
          ])}
        />
        <hr className="my-2" />

        <RangeFieldSet
          label="エントリー価格帯"
          fieldType="number"
          minValue={minEntryPrice}
          maxValue={maxEntryPrice}
          setMinValue={(newValue) => {
            setMinEntryPrice(newValue?.replace(/[^0-9]/g, ''));
          }}
          setMaxValue={(newValue) => {
            setMaxEntryPrice(newValue?.replace(/[^0-9]/g, ''));
          }}
          hasMinValueError={invalidInputAttributes.includes(InputAttributeName.MinEntryPrice)}
          hasMaxValueError={invalidInputAttributes.includes(InputAttributeName.MaxEntryPrice)}
          isFieldSetOpen={isEntryPriceFieldSetOpen}
          setIsFieldSetOpen={setIsEntryPriceFieldSetOpen}
          hasInvalidValues={includeInvalidAttributes([
            InputAttributeName.MinEntryPrice,
            InputAttributeName.MaxEntryPrice
          ])}
        />
        <hr className="my-2" />
      </>}

      <a onClick={() => setIsMaxHoldingBusinessDaysFieldOpen(true)}>
        <div className="d-flex justify-content-between align-items-center">
          <label className="trade-offcanvas__label">
            <i className={
              classnames("las", isMaxHoldingBusinessDaysFieldOpen ? "la-angle-down" : "la-angle-right")
            }></i>
            {" "}
            最大保有日数
          </label>
          {!isMaxHoldingBusinessDaysFieldOpen && <div className="text-end">
            {!!maxHoldingBusinessDays ? (
              `${maxHoldingBusinessDays}営業日後に自動決済`
            ) : (
              "指定しない"
            )}
          </div>}
        </div>
      </a>

      {isMaxHoldingBusinessDaysFieldOpen && <>
        <div className="d-flex">
          <div className="input-group">
            <input
              type="number"
              className={classnames(
                "form-control", "trade-offcanvas__form-control",
                includeInvalidAttributes([InputAttributeName.MaxHoldingBusinessDays]) && "is-invalid",
              )}
              value={maxHoldingBusinessDays ?? ""}
              onChange={(e) => setMaxHoldingBusinessDays(e.target.value?.replace(/[^0-9]/g, ''))}
            />
            <span className="input-group-text trade-offcanvas__input-group-text">営業日後に自動決済</span>
          </div>
          <div className="mx-2">
            <button
              type="button"
              className="btn btn-outline-primary trade-offcanvas__square-button"
              onClick={closeMaxHoldingBusinessDaysFieldSet}
            >
              <i className="las la-check"></i>
            </button>
          </div>
          <div className="text-danger d-flex align-items-center">
            <a onClick={resetMaxHoldingBusinessDaysFieldSet}>
              <i className="las la-times"></i>
            </a>
          </div>
        </div>
        <p className="trade-offcanvas__help-text">
          例えば4月1日月曜日の14:00にエントリーし、2営業日後に決済する設定にした場合、4月3日の14:00に自動決済されます
        </p>
      </>}
      <hr className="my-2" />

      <a onClick={() => {
        if (!canEditTrailingStop) return;
        setIsTrailingStopFieldSetOpen(true);
      }}>
        <div className="d-flex justify-content-between align-items-center">
          <label className="trade-offcanvas__label">
            {canEditTrailingStop && <i className={
              classnames("las", isTrailingStopFieldSetOpen ? "la-angle-down" : "la-angle-right")
            }></i>}
            {" "}
            トレーリングストップ
          </label>
          {!isTrailingStopFieldSetOpen && <div className="text-end">
            {!!trailingStopTriggerPct && trailingStopProfitPct ? (
              `含み益が${trailingStopTriggerPct}%に達したあと${trailingStopProfitPct}%に下がったら自動決済`
            ) : (
              "指定しない"
            )}
          </div>}
        </div>
      </a>

      {isTrailingStopFieldSetOpen && <>
        <div className="d-flex w-100">
          <div className="flex-grow-1">
            <div className="input-group">
              <span className="input-group-text trade-offcanvas__input-group-text">含み益が</span>
              <input
                type="number"
                className={classnames(
                  "form-control", "trade-offcanvas__form-control",
                  includeInvalidAttributes([InputAttributeName.TrailingStopTriggerPct]) && "is-invalid",
                )}
                value={trailingStopTriggerPct ?? ""}
                onChange={(e) => {
                  if (e.target.value === '' || /^\d{1,4}\.?\d{0,1}$/.test(e.target.value)) {
                    setTrailingStopTriggerPct(e.target.value);
                  }
                }}
                disabled={!canEditTrailingStop}
              />
              <span className="input-group-text trade-offcanvas__input-group-text">%に達したあと</span>
            </div>
            <div className="input-group">
              <input
                type="number"
                className={classnames(
                  "form-control", "trade-offcanvas__form-control",
                  includeInvalidAttributes([InputAttributeName.TrailingStopProfitPct]) && "is-invalid",
                )}
                value={trailingStopProfitPct ?? ""}
                onChange={(e) => {
                  if (e.target.value === '' || /^\d{1,4}\.?\d{0,1}$/.test(e.target.value)) {
                    setTrailingStopProfitPct(e.target.value);
                  }
                }}
                disabled={!canEditTrailingStop}
              />
              <span className="input-group-text trade-offcanvas__input-group-text">%に下がったら売却</span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="mx-2">
              <button
                type="button"
                className="btn btn-outline-primary trade-offcanvas__square-button"
                onClick={closeTrailingStopFieldSet}
              >
                <i className="las la-check"></i>
              </button>
            </div>
            <div className="text-danger d-flex align-items-center">
              <a onClick={resetTrailingStopFieldSet}>
                <i className="las la-times"></i>
              </a>
            </div>
          </div>
        </div>
        <p className="trade-offcanvas__help-text">
          例えば含み益が10%に達したあと、5%に下がったら自動決済される設定にした場合、1000円でエントリーし、1100円に達したあと、1050円に含み益が下がったら自動成行決済されます。
          <br />
          トレーリングストップの目標含み益が達成された後には、設定変更できません。
        </p>
      </>}
      <hr className="my-2" />

      <div className="row">
        <div className="col-6 pe-1">
          <RequiredNumberField
            label="利益確定"
            appendedText="%"
            fieldStep={0.1}
            value={takeProfitPct ?? ''}
            setValue={(newValue) => {
              if (newValue === '' || /^\d{1,4}\.?\d{0,1}$/.test(newValue)) {
                setTakeProfitPct(newValue);
              }
            }}
            hasValueError={didTrySubmit && invalidInputAttributes.includes(InputAttributeName.TakeProfitPct)}
          />
        </div>
        <div className="col-6 ps-1">
          <RequiredNumberField
            label="損切り"
            appendedText="%"
            fieldStep={0.1}
            value={stopLossPct ?? ''}
            setValue={(newValue) => {
              if (newValue === '' || /^\d{1,4}\.?\d{0,1}$/.test(newValue)) {
                setStopLossPct(newValue);
              }
            }}
            hasValueError={didTrySubmit && invalidInputAttributes.includes(InputAttributeName.StopLossPct)}
          />
        </div>
      </div>
      <p className="trade-offcanvas__help-text">
        含み益、含み損が一定に達した場合に、成行決済を行います
      </p>
      <hr className="my-2" />

      {(isEntryCandidate || !isExitCandidate) && <div className="row">
        <div className="col-6 pe-1">
          <RequiredNumberField
            label={`売買株数（${instrument.tradingLot}株単位）`}
            appendedText="株買い"
            value={quantity ?? ''}
            setValue={(newValue) => {
              setQuantity(newValue?.replace(/[^0-9]/g, ''));
            }}
            hasValueError={didTrySubmit && invalidInputAttributes.includes(InputAttributeName.Quantity)}
          />
        </div>
        <div className="col-6 ps-1">
          <label className="trade-offcanvas__label">
            概算売買代金
          </label>
          <div className="text-center mt-2">
            {!!quantity && !!maxEntryPrice ? (
              (Number(maxEntryPrice) * Number(quantity)).toLocaleString() + '円'
            ) : '表示できません' }
          </div>
        </div>
      </div>}

      <div className="text-center mt-4">
        <div className="d-flex justify-content-center">
          {deleteFormUrl && <button type="button"
            className="btn btn-outline-danger btn-rounded me-5"
            disabled={isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              if (confirm("かんたん自動売買設定を削除しますか？")) {
                setIsSubmitting(true);
                deleteFormRef.current.submit();
              }
            }}
          >
            設定削除
          </button>}
          <button type="submit"
            className="btn btn-success btn-rounded"
            disabled={!canSubmit}
            onClick={(e)=> {
              setDidTrySubmit(true);
              e.preventDefault();
              setIsSubmitting(true);
              formRef.current.submit();
            }}
          >
            {isPersisted ? (isEntryCandidate || isExitCandidate ? "設定を変更" : "再設定") : "自動売買を開始"}
          </button>
        </div>
        <p className="trade-offcanvas__help-text">
          エントリー時間、価格帯によっては、すぐさまエントリーされる場合があります
          {isExitCandidate && <span>
            <br />
            ポジション保有中に設定を変更すると、すぐさま決済される場合があります
          </span>}
        </p>
      </div>

      <form action={formUrl} method="post" ref={formRef}>
        <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
        {formMethod !== 'post' && <input type="hidden" name="_method" value={formMethod} autoComplete="off" />}
        <input type="hidden" name="ai_trade_strategy[ticker]" value={instrument?.ticker} />
        <input type="hidden" name="ai_trade_strategy[entry_time_not_before]" value={entryTimeNotBefore ?? ''} />
        <input type="hidden" name="ai_trade_strategy[entry_time_not_after]" value={entryTimeNotAfter ?? ''} />
        <input type="hidden" name="ai_trade_strategy[min_entry_price]" value={minEntryPrice ?? ''} />
        <input type="hidden" name="ai_trade_strategy[max_entry_price]" value={maxEntryPrice ?? ''} />
        <input type="hidden" name="ai_trade_strategy[max_holding_business_days]" value={maxHoldingBusinessDays ?? ''} />
        <input type="hidden" name="ai_trade_strategy[take_profit_pct]" value={takeProfitPct ?? ''} />
        <input type="hidden" name="ai_trade_strategy[stop_loss_pct]" value={stopLossPct ?? ''} />
        <input type="hidden" name="ai_trade_strategy[quantity]" value={quantity ?? ''} />
        <input type="hidden" name="ai_trade_strategy[trading_mode]" value={tradingMode} />
        {trailingStopTriggerPct && trailingStopProfitPct && <>
          <input type="hidden" name="ai_trade_trailing_stop_step[trigger_pct]" value={trailingStopTriggerPct} />
          <input type="hidden" name="ai_trade_trailing_stop_step[profit_pct]" value={trailingStopProfitPct} />
        </>}
      </form>

      {deleteFormUrl && <form action={deleteFormUrl} method="post" ref={deleteFormRef}>
        <input type="hidden" name="authenticity_token" value={Rails.csrfToken()} />
        <input type="hidden" name="_method" value="delete" autoComplete="off" />
      </form>}
    </div>
  );
};

export default StrategyForm;