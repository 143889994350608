import * as bootstrap from 'bootstrap/dist/js/bootstrap';

// Frontend form validation
document.addEventListener('turbolinks:load', () => {
  const marketCheckboxes = document.querySelectorAll('.market-checkbox');
  const submitButton = document.getElementById('submit-button');
  if (!marketCheckboxes || !submitButton) {
    return;
  }
  const toggleSubmitButton = () => {
    if (document.querySelectorAll('.market-checkbox:checked').length > 0) {
      submitButton.disabled = false;
      submitButton.classList.remove('disabled');
    } else {
      submitButton.disabled = true;
      submitButton.classList.add('disabled');
    }
  };
  marketCheckboxes.forEach((checkbox) => {
    checkbox.addEventListener('change', toggleSubmitButton);
  });
  toggleSubmitButton();

  const toggleAllMarkets = document.getElementById('toggle-all-markets');
  if (!toggleAllMarkets) {
    return;
  }
  toggleAllMarkets.addEventListener('click', (e) => {
    if (document.querySelectorAll('.market-checkbox:checked').length === marketCheckboxes.length) {
      marketCheckboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    } else {
      marketCheckboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
    }
    toggleSubmitButton();
  });
});

// HACK Rerender charts on modal show
// https://github.com/apache/echarts/issues/10478
// https://github.com/railsjazz/rails_charts/blob/b424bd1eb0a047080afb66a939ae6279f49bb82c/lib/rails_charts/base_chart.rb#L47
document.addEventListener('turbolinks:load', () => {
  // Ones in a modal should be rerendered on modal show
  const instrumentModals = document.querySelectorAll('.instrument-modal');
  instrumentModals.forEach(instrumentModal => {
    instrumentModal.addEventListener('shown.bs.modal', event => {
      const ohlcChartWrapper = event.target.querySelector('.ohlc-chart');
      if (window.RailsCharts.charts[ohlcChartWrapper.id]) {
        window[`destroy_${ohlcChartWrapper.id}`]();
      }
      window[`init_${ohlcChartWrapper.id}`]();
    });
  });

  // Ones not in a modal should be rerendered on window resize
  window.addEventListener('resize', () => {
    const ohlcChartWrappers = document.querySelectorAll('.ohlc-chart');
    ohlcChartWrappers.forEach(ohlcChartWrapper => {
      if (window.RailsCharts.charts[ohlcChartWrapper.id]) {
        window[`destroy_${ohlcChartWrapper.id}`]();
      }
      window[`init_${ohlcChartWrapper.id}`]();
    });
  });
});

// Append query string upon instrument modal open
document.addEventListener('turbolinks:load', () => {
  const instrumentModals = document.querySelectorAll('.instrument-modal');

  instrumentModals.forEach(instrumentModal => {
    instrumentModal.addEventListener('shown.bs.modal', event => {
      let currentUrl = new URL(window.location.href);
      const instrumentModalId = event.target.getAttribute('id');
      currentUrl.searchParams.set('active_instrument_modal_id', instrumentModalId);
      window.history.replaceState({}, '', currentUrl);
    });
    instrumentModal.addEventListener('hidden.bs.modal', event => {
      let currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete('active_instrument_modal_id');
      window.history.replaceState({}, '', currentUrl);
    });
  });

  if (window.location.search.includes('active_instrument_modal_id')) {
    const activeInstrumentModalId = new URLSearchParams(window.location.search).get('active_instrument_modal_id');
    const activeInstrumentModal = document.getElementById(activeInstrumentModalId);
    if (activeInstrumentModal) {
      const modal = new bootstrap.Modal(activeInstrumentModal);
      modal.show();
    }
  }
});
